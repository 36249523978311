<template>
  <base-container class="pb-5">
    <template v-slot:header>
      <v-btn
        class="mx-2"
        @click="$router.go(-1)"
        color="accent"
        :disabled="loading"
        >Voltar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        @click="saveDocument"
        :disabled="loading || isFinished"
        color="accent"
        >Salvar</v-btn
      >
    </template>
    <v-card :disabled="loading" :loading="loading">
      <v-card-title
        ><span>Venda</span>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="inserting"
          @click="cancelDocument"
          rounded="rounded"
          small="small"
          >{{ cancelLabel }}</v-btn
        >
        <v-btn
          class="ml-3 white--text"
          :disabled="isFinished"
          @click="finalizeDocument"
          color="accent"
          rounded="rounded"
          small="small"
          >Finalizar</v-btn
        >
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                :value="createdAtComputed"
                append-icon="mdi-calendar-today"
                disabled="disabled"
                filled="filled"
                label="Iniciada em"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                :value="endedAtComputed"
                append-icon="mdi-calendar-check"
                disabled="disabled"
                filled="filled"
                label="Finalizada em"
                placeholder="Não finalizado"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                :value="doc.status"
                append-icon="mdi-progress-check"
                disabled="disabled"
                filled="filled"
                label="Status"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                :disabled="!isNew"
                :error-messages="error.customer"
                :value="doc.customer.name"
                @click:prepend-inner="searchAccount('Cliente')"
                @focus="error.customer = ''"
                filled="filled"
                label="Cliente"
                prepend-inner-icon="mdi-account-search"
                readonly="readonly"
                ref="customer"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                :disabled="!isNew"
                :error-messages="error.account"
                :value="doc.account.name"
                @click:prepend-inner="searchAccount('Outros')"
                @focus="error.account = ''"
                filled="filled"
                label="Atendente"
                prepend-inner-icon="mdi-account-search"
                readonly="readonly"
                ref="account"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field
                class="font-weight-black"
                :value="$utils.decimal(totalItems, 2)"
                disabled="disabled"
                filled="filled"
                label="Total (R$)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-title class="pt-0" disabled="disabled"
        ><span>Itens</span>
        <v-spacer></v-spacer>
        <v-tooltip color="#212121" bottom="bottom" v-if="isNew">
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-3"
              @click="loadCustomerItems()"
              icon="icon"
              key="load"
              large="large"
              text="text"
              v-on="on"
            >
              <v-icon :size="24">mdi-sync</v-icon>
            </v-btn> </template
          ><span>Itens de agendamentos</span>
        </v-tooltip>
        <v-btn
          class="elevation-2"
          @click="addItems"
          color="accent"
          dark="dark"
          fab="fab"
          key="add"
          small="small"
          title="Adicionar item"
          v-if="isNew"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <document-items-data-table
        :disableEdit="isFinished"
        :items="items"
        :loading="loadingItems"
        @item-add="handleItemAdd"
        @item-delete="handleItemDelete"
        @item-navigate="navigateTo"
        @item-save="handleItemSave"
        ref="items"
        scheduleLink="scheduleLink"
        type="document"
      ></document-items-data-table>
    </v-card>
    <collection-dialog
      :filters="accountsFilters"
      :title="dialogLabel"
      @data-selected="selectAccount"
      type="accounts"
      v-if="collectionDialog"
      v-model="dialogCustomer"
    ></collection-dialog>
  </base-container>
</template>

<script>
import BaseContainer from "@/components/BaseContainer";
import CollectionDialog from "@/components/CollectionDialog.vue";
import DocumentItemsDataTable from "@/components/DocumentItemsDataTable.vue";

import { db } from "../../plugins/google/firebase";
import $store from "@/store";
import documentItemsMixin from "@/mixin/documentItemsMixin";
import firestore from "../../plugins/firestore";

const { COLLECTION_DOCUMENTS } = $store.getters,
  SOURCE = { source: "cache" };

export default {
  name: "document-edit",
  props: ["type", "customer"],
  mixins: [documentItemsMixin],
  components: { BaseContainer, CollectionDialog, DocumentItemsDataTable },

  data: () => ({
    loading: true,
    inserting: true,

    collectionDialog: false,
    dialogCustomer: false,
    dialogLabel: "",
    customerId: null,

    doc: {
      account: { name: "" },
      createdAt: "",
      customer: { name: "" },
      endedAt: "",
      status: "",
    },

    error: {
      account: "",
      customer: "",
    },

    accountsFilters: {
      role: "Cliente",
    },

    itemsOptions: { size: 0, total: 0 },
  }),

  created() {
    this.inserting = this.type === "add";
  },

  mounted() {
    this.onStart();
  },

  computed: {
    cancelLabel() {
      switch (this.doc.status) {
        case "Novo":
          return "Excluir";
        case "Cancelado":
          return "Restaurar";
        default:
          return "Cancelar";
      }
    },

    createdAtComputed() {
      if (!this.doc.createdAt) {
        return "";
      }
      return this.$utils.calendar(this.doc.createdAt);
    },

    endedAtComputed() {
      if (!this.doc.endedAt) {
        return "";
      }
      return this.$utils.calendar(this.doc.endedAt);
    },

    isNew() {
      return this.doc.status === "Novo";
    },

    isFinished() {
      if (!this.doc.status) {
        return true;
      }
      return (
        this.doc.status === "Finalizado" || this.doc.status === "Cancelado"
      );
    },
  },

  methods: {
    async onStart() {
      if (this.$route.params.customer) {
        this.initDoc();

        setTimeout(() => {
          this.loadCustomerItems(this.$route.params.customer);
          this.loading = false;
        }, 600);
      } else if (this.$route.params.docRef) {
        this.getDataFromApi(this.$route.params.docRef);
      } else if (this.type === "edit") {
        this.$router.replace({ name: "documents" });
      } else {
        this.loading = false;
        this.initDoc();
      }
    },

    initDoc() {
      this.docRef = db.collection(COLLECTION_DOCUMENTS).doc();
      this.doc.createdAt = this.$day().toJSON();
      this.doc.status = "Novo";
    },

    addItems() {
      if (!this.doc.customer.id) {
        this.error.customer = "Por favor selecione o cliente";
        return;
      }
      this.$refs.items && this.$refs.items.searchItems();
    },

    loadCustomerItems(customer) {
      if (customer && customer.id) {
        this.customerId = customer.id;
        this.doc.customer = customer;
      }

      if (!customer && !this.doc.customer.id) {
        this.error.customer = "Por favor selecione o cliente";
        return;
      }

      this.$nextTick(() => {
        this._getCustomerItems({
          customerId: this.doc.customer.id,
          documentId: this.docRef.id,
        });
      });
    },

    getDataFromApi(docRef) {
      this.docRef = db.collection(COLLECTION_DOCUMENTS).doc(docRef);

      this.docRef
        .get(SOURCE)
        .then((snap) => {
          const doc = snap.data();
          this.doc = doc;
        })
        .then(() => {
          setTimeout(() => this._getDocumentItems(this.docRef.id), 250);
        })
        .catch(() => {
          this.$snackbar.show({ title: "Erro ao buscar dados, sem conexão" });
        })
        .finally(() => {
          setTimeout(() => (this.loading = false), 600);
        });
    },

    handleItemAdd(v) {
      const item = this._addItem({
        item: v,
        customerId: this.doc.customer.id,
        documentId: this.docRef.id,
      });

      if (!this.inserting) {
        firestore.setDocumentItem(item, this.totalItems).catch(() => {
          this.$messages.error({ text: "Houve um problema ao salvar o item" });
        });
      }
    },

    handleItemDelete(v) {
      this.loadingItems = true;
      firestore
        .deleteDocumentItem(v, this.totalItems)
        .then(() => this._deleteItem(v))
        .catch(() => {
          this.$messages.error({
            text: "Algo deu errado ao remover o item. Parece que estamos sem internet",
          });
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },

    handleItemSave(v) {
      const item = this._saveItemAction(v);
      if (!this.inserting) {
        firestore.setDocumentItem(item, this.totalItems).catch(() => {
          this.$messages.error({ text: "Houve um problema ao salvar o item" });
        });
      }
    },

    selectAccount(data) {
      if (!data[0]) {
        return;
      }

      const doc = { id: data[0].id, name: data[0].name };

      if (this.accountsFilters.role === "Cliente") {
        if (this.customerId && this.customerId !== doc.id) {
          this.items = [];
        }
        this.$nextTick(() => {
          this.customerId = doc.id;
          this.doc.customer = doc;
          this.error.customer && (this.error.customer = "");
        });
      } else {
        this.doc.account = doc;
        this.error.account && (this.error.account = "");
      }
    },

    searchAccount(role) {
      if (!this.collectionDialog) {
        this.collectionDialog = true;
      }
      this.$nextTick(() => {
        this.accountsFilters.role = role;
        this.dialogLabel =
          role === "Cliente"
            ? "Selecione um cliente"
            : "Selecione um atendente";
        setTimeout(() => {
          this.dialogCustomer = true;
        }, 250);
      });
    },

    saveDocument() {
      this.validate() && this.saveDocumentFirestore();
    },

    async saveDocumentFirestore(cmd) {
      try {
        this.loading = true;

        const doc = JSON.parse(JSON.stringify(this.doc));
        doc.total = this.totalItems;
        doc.updatedAt = new Date().toJSON();

        if (cmd) {
          doc.endedAt = this.$day().toJSON();
          doc.status = cmd === "f" ? "Finalizado" : "Cancelado";
        }

        const items = this._getFirestoreItems({ documentId: this.docRef.id }),
          inserting = this.inserting;
        await firestore.setDocument(this.docRef, doc, {
          cmd,
          inserting,
          items,
        });

        this.doc.endedAt = doc.endedAt;
        this.doc.status = doc.status;
        this.inserting = false;
        this.$snackbar.show({ title: "Dados salvos com sucesso" });
      } catch (e) {
        this.$messages.error({ text: "Não foi possível salvar os dados" });
      } finally {
        this.loading = false;
      }
    },

    cancelDocument() {
      if (this.doc.status === "Novo") {
        if (this.items.length > 0) {
          return this.$snackbar.show({
            title: "Remova todos os itens antes de excluir a venda",
          });
        }

        const actionDelete = async () => {
          try {
            this.docRef.delete();
            this.$snackbar.show({ title: "Venda foi excluída com sucesso" });
            setTimeout(() => this.$router.go(-1), 400);
          } catch (e) {
            this.$messages.error({
              text: "Não foi possível excluir esta venda",
            });
          }
        };

        this.$dialog.show(
          "Excluir venda",
          "Deseja realmente excluir esta venda?",
          [
            { text: "cancelar" },
            { text: "excluir", style: "destrutive", onClick: actionDelete },
          ],
        );
        return;
      }

      if (this.doc.status === "Cancelado") {
        const actionRestore = () => {
          this.doc.endedAt = "";
          this.doc.status = "Novo";
          this.docRef
            .update({ endedAt: this.doc.endedAt, status: this.doc.status })
            .catch(() => null);
        };

        this.$dialog.show(
          "Restaurar venda",
          "Deseja restaurar o status desta venda para edição?",
          [
            { text: "cancelar" },
            { text: "restaurar", style: "destrutive", onClick: actionRestore },
          ],
        );
        return;
      }
      this.$dialog.show(
        "Cancelar venda",
        "Deseja realmente cancelar esta venda?",
        [
          { text: "Não cancelar" },
          {
            text: "Cancelar venda",
            style: "destrutive",
            onClick: () => this.saveDocumentFirestore("c"),
          },
        ],
      );
    },

    finalizeDocument() {
      if (!this.validate()) {
        return;
      }

      this.$dialog.show(
        "Finalizar venda",
        "Deseja prosseguir e finalizar esta venda?",
        [
          { text: "cancelar" },
          {
            text: "finalizar",
            style: "destrutive",
            onClick: () => this.saveDocumentFirestore("f"),
          },
        ],
      );
    },

    validate() {
      if (!this.doc.customer.id) {
        this.error.customer = "Por favor selecione o cliente";
      }
      if (!this.doc.account.id) {
        this.error.account = "Por favor selecione o atendente";
      }
      if (Object.values(this.error).filter((e) => !!e).length) {
        return false;
      }
      if (!this.items.length) {
        this.$snackbar.show({
          title: "Adicione pelo menos um item para prosseguir",
        });
        return false;
      }
      return true;
    },

    navigateTo(v) {
      if (this.type === "add" || !v.scheduleId) {
        return;
      }

      const onClick = () =>
        setTimeout(() => {
          this.$router.push({
            name: "schedule-edit",
            params: { type: "edit", docRef: v.scheduleId },
          });
        }, 100);

      this.$dialog.show(
        "Abrir agendamento",
        "Deseja continuar e abrir o agendamento correspondente ao item?",
        [{ text: "cancelar" }, { text: "Abrir", style: "destrutive", onClick }],
        { persistent: false },
      );
    },
  },
};
</script>
